import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/po'

export default {
  all (idProyek) {
    return httpClient.get(`${endpoint}/all/${idProyek}`)
  },

  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  getPoTermin (idPo, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idPo}/termin${queryParams}`)
  },

  getPoFiles (idPo, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idPo}/files${queryParams}`)
  },

  show (idPo) {
    return httpClient.get(`${endpoint}/${idPo}`)
  },

  showTermin (idPoD, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/termin/${idPoD}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  claimTerminPayment (params) {
    return httpClient.post(`${endpoint}/claimTerminPayment`, params)
  }
}
