import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/general/printOut'

export default {
  get (type, params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${type}${queryParams}`)
  }
}
