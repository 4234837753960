import PrintoutRepository from '@/repositories/general/printout-repository'
import { POSITION } from 'vue-toastification'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  data () {
    return {
      isLoadingGeneratePrintOutFile: false
    }
  },
  methods: {
    showPrintOutFile (type, params) {
      if (!this.isLoadingGeneratePrintOutFile) {
        this.isLoadingGeneratePrintOutFile = true
        const toastId = this.$toast.info('Generating file, please wait...', {
          position: POSITION.BOTTOM_RIGHT,
          timeout: false,
          closeButton: false,
          closeOnClick: false,
          draggable: false
        })

        return PrintoutRepository.get(type, convertToFormData(params))
          .then(response => {
            const url = response.data.data.file_url
            this.$iframeModal.show(url)
          })
          .catch(error => {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          })
          .finally(() => {
            this.$toast.dismiss(toastId)
            this.isLoadingGeneratePrintOutFile = false
          })
      }
    }
  }
}
